import BlockContainer from '@block/BlockContainer';
import useInFooter from '@comp/useInFooter';
import styled from '@emotion/styled';
import { IParagraph } from '@interface/IParagraph';
import { Flex, Image, Text } from '@mantine/core';
import React from 'react';
import { processFormattedText } from '@lib/utils';

const ParagraphWrap = styled(Flex)`
	&.right {
		flex-direction: row-reverse;
		@media (max-width: ${({ theme }) => `${theme.breakpoints.sm}px`}) {
			flex-direction: column;
		}
	}
`;

const ImageWrap = styled(Flex)`
	max-width: 13%;
`;

const Paragraph: React.FC<{data: IParagraph, children?: React.ReactNode }> = ({ data }) => {
	const { body, image, imagePosition } = data;
	const html = processFormattedText(body);
	const isInFooter = useInFooter();
	return (
		<BlockContainer size='xl' mt="xs" w={isInFooter ? '100%' : ''}>
			<ParagraphWrap
				bg="paragraphBackground.0"
				className={imagePosition?.toLowerCase() || ''}
				direction={{ base: 'column', sm: 'row' }}
				fz="lg"
			>
				{image ? (
					<ImageWrap ml="sm" mr="md">
						<Image src={`${image}?width=600`} />
					</ImageWrap>
				) : null}
				<Text color="paragraphColor.0" dangerouslySetInnerHTML={{ __html: html }} />
			</ParagraphWrap>
		</BlockContainer>
	);
};
export default Paragraph;

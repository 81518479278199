import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useBreakpoints = () => {

	const theme = useMantineTheme();
	const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
	const isMd = useMediaQuery(`(min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.lg}px)`);
	const isLg = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px) and (max-width: ${theme.breakpoints.xl}px)`);
	const isXl = useMediaQuery(`(min-width: ${theme.breakpoints.xl}px)`);

	return {
		isSm,
		isMd,
		isLg,
		isXl,
	};
};

export default useBreakpoints;

import BlockContainer from '@block/BlockContainer';
import styled from '@emotion/styled';
import { Bulleting, IList } from '@interface/IList';
import { Flex, List as MantineList } from '@mantine/core';
import React from 'react';
import { processFormattedText } from '@lib/utils';

export const ListWrap = styled(Flex)`
	& li::marker {
		font-size: ${({ theme }) => `${theme.fontSizes.lg}px`};
		color: ${({ theme }) => theme.colors.accent[0]};
		font-weight: 700;
	}}
`;


const List: React.FC<{data: IList, children?: React.ReactNode }> = ({ data }) => {
	const isBullet = data.bulleting === Bulleting.Bulleted;
	return (
		<BlockContainer size='xl' mt="md" mb="md" w="100%">
			<ListWrap className={isBullet ? '' : 'numbered'}>
				<MantineList
					type={isBullet ? 'unordered' : 'ordered'}
					fz="lg"
				>
					{
						data.body.map((paragraph, index) => {
							const html = processFormattedText(paragraph);
							return (
								<MantineList.Item key={index}>
									<div dangerouslySetInnerHTML={{ __html: html }} />
								</MantineList.Item>
							);
						})
					}
				</MantineList>
			</ListWrap>
		</BlockContainer>

	);
};

export default List;

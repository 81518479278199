import Block from '@block/Block';
import { IBlock } from '@interface/IBlock';
import { getBiteContent } from '@lib/api/contentApi';
import { mergeBiteParams } from '@lib/mergeBiteParams';
import { useAuthTokenContext } from '@lib/useAuthTokenContext';
import { useUserContext } from '@lib/useUserContext';
import { Flex, Loader, Text } from '@mantine/core';
import { randomId } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const BiteView: React.FC = () => {
	const isDebugging = process.env.REACT_APP_DEBUG === 'true';
	const userContext = useUserContext();
	const authTokenContext = useAuthTokenContext();
	const [ searchParams ] = useSearchParams();
	const [ blocks, setBlocks ] = useState<IBlock[] | null>(null);
	const [ loading, setLoading ] = useState(false);

	const token = searchParams.get('token');
	const id = searchParams.get('id');
	const type = searchParams.get('type');
	const locale = searchParams.get('locale');
	const contentId = searchParams.get('contentId');
	const contentType = searchParams.get('contentType');
	const [ error, setError ] = useState('');

	const isTokenExists = !!(token || authTokenContext?.token);

	useEffect(() => {
		if (!isTokenExists && !isDebugging) {
			return;
		}

		async function fetchBlocks () {
			if (isDebugging) {
				try {
					const dummyData = await import('@lib/dummyRenderTree');
					setBlocks(dummyData.dummyBite.data.blocks);
				} catch (err) {
					setError('Failed to load dummy data');
				}
				return;
			}

			const biteParams = mergeBiteParams(userContext.bite, {
				locale,
				contentId,
				contentType,
			}, token);

			if (!biteParams.locale || !biteParams.contentId || !biteParams.contentType) {
				return;
			}

			try {
				setLoading(true);
				setError('');

				const data = await getBiteContent({
					token,
					id,
					type,
					locale: biteParams.locale,
					contentId: biteParams.contentId,
					contentType: biteParams.contentType,
					authToken: authTokenContext.token,
				});
				setBlocks(data.data.blocks);
			} catch (error) {
				setError((error as Error)?.message || 'Unknown error');
			} finally {
				setTimeout(() => setLoading(false), 1200);
			}
		}
		fetchBlocks();
	}, [ isTokenExists, isDebugging, id, type, locale, contentId, contentType, userContext, authTokenContext, token ]);

	if (error) {
		return (
			<Text ml="xs" data-testid="error" color="red.9">{error}</Text>
		);
	}

	if (loading) {
		return (
			<Flex w="100vw" h="100vh" align="center" justify="center">
				<Loader ml="xs" data-testid="loader" />
			</Flex>
		);
	}

	if (blocks && blocks.length === 0) {
		return <Text ml="xs" data-testid="empty">Bite is empty</Text>;
	}

	return (
		<>
			{
				blocks?.map(
					(block: IBlock) => (
						<Block data={block} key={`${block.id}-${randomId()}`}
						/>
					),
				)
			}
		</>
	);
};

export default BiteView;
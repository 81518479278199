import BlockContainer from '@block/BlockContainer';
import styled from '@emotion/styled';
import { IPillar } from '@interface/IPillar';
import { Flex, Text } from '@mantine/core';

const StyledPillar = styled(Flex)(({ theme }) => ({
	position: 'relative',
	height: '100%',
	background: '#f6f6f6',
	color: '#8a96a4',
	'.content': {
		fontSize: '1.125rem',
		padding: '20px 10px 40px 10px',
		textAlign: 'center',
	},
	'.number': {
		background: theme.colors.pillarNumberBackground,
		color: theme.colors.pillarNumberColor,
		border: `8px solid #ffffff`,
		borderRadius: '100px',
		boxSizing: 'content-box',
		fontSize: '2rem',
		fontWeight: 600,
		height: '60px',
		left: '50%',
		marginLeft: '-40px',
		minWidth: '60px',
		padding: 0,
		position: 'absolute',
		top: '-40px',
		width: '60px',
		textAlign: 'center',
		'& + .content': {
			padding: '60px',
			fontWeight: 700,
			fontSize: '28px',
			lineHeight: '36px',
			letterSpacing: '0.25px',
		},
	},
}));


const Pillar: React.FC<{data: IPillar, children?: React.ReactNode }> = ({ data }) => {
	const { number, text } = data;

	return (
		<BlockContainer size="xl" p="0" sx={{ flex: 1, alignSelf: 'stretch' }}>
			<StyledPillar>
				{number && <Text className="number">{number}</Text>}
				<Text className="content">{text}</Text>
			</StyledPillar>
		</BlockContainer>
	);
};

export default Pillar;

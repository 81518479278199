import { GetBiteContentParams, GenerateBiteTokenParams, GetPreviewParams, GetManagerPackageParams, GeneratorLoginParams } from '@lib/types/requestParams.type';
import { BiteContentResponse, LoginResponse } from '@lib/types/responses.type';
import * as Sentry from '@sentry/react';

class DefinedError extends Error {}
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function getBiteContent (params: GetBiteContentParams): Promise<BiteContentResponse> {
	try {
		const url = new URL(`${apiBaseURL}/bite`);
		Object.entries(params).forEach(([ key, value ]) => {
			if (value !== null && value !== undefined) {
				url.searchParams.append(key, value as string);
			}
		});

		const response = await fetch(url, { 
			method: 'GET', 
			headers: {
				'Authorization': `Bearer ${params.authToken}`,
			}, 
		});

		const data = await response.json();

		if (response.ok) {
			return data as BiteContentResponse;
		}

		if (data.message) {
			throw new DefinedError(data.message);
		}

		throw new DefinedError('Unknown error');
	} catch (error) {
		if (error instanceof DefinedError) {
			throw error;
		}
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
		throw error;
	}
}

export async function getManagerPackage (params: GetManagerPackageParams): Promise<BiteContentResponse> {
	try {
		const url = new URL(`${apiBaseURL}/public/module/${params.id}/manager-package/${params.locale}`);
		const response = await fetch(url, { 
			method: 'GET', 
			headers: {
				'Authorization': `Bearer ${params.authToken}`,
			}, 
		});
		const data = await response.json();

		if (response.ok) return data as BiteContentResponse;
		if (data.message) throw new DefinedError(data.message);
		throw new DefinedError('Unknown error');
	} catch (error) {
		if (error instanceof DefinedError) throw error;
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
		throw error;
	}
}

export async function generatorLogin (payload: GeneratorLoginParams): Promise<LoginResponse> {
	try {
		const { username, password } = payload;
		const url = new URL(`${apiBaseURL}/generator/login`);
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify({
				name: username,
				password,
			}),
		});
		const data = await response.json();

		if (response.ok) return data;
		if (data.message) throw new DefinedError(data.message);
		throw new DefinedError('Unknown error');
	} catch (error) {
		if (error instanceof DefinedError) throw error;
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
		throw error;
	}
}

export async function generateBiteToken (params: GenerateBiteTokenParams): Promise<string> {
	try {
		const url = new URL(`${apiBaseURL}/generator/token/bite`);
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'content-type': 'application/json; charset=utf-8',
				'Authorization': `Bearer ${params.authToken}`,
			},
			body: JSON.stringify({
				token: params.token,
				request: { id: params.id, type: params.type, locale: params.locale ?? 'en-us' },
			}),
		});
		const data = await response.json();

		if (response.ok) return data.data as string;
		if (data.message) throw new DefinedError(data.message);
		throw new DefinedError('Unknown error');
	} catch (error) {
		if (error instanceof DefinedError) throw error;
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
		throw error;
	}
}

export async function getPreview (params: GetPreviewParams): Promise<BiteContentResponse> {
	try {
		const urlParams = new URLSearchParams(params as Record<string, string>);
		const url = new URL(`${apiBaseURL}/${params.type}?${urlParams}`);
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'content-type': 'application/json; charset=utf-8',
				'Authorization': `Bearer ${params.authToken}`,
			},
		});
		const data = await response.json();

		if (response.ok) return data as BiteContentResponse;
		if (data.message) throw new DefinedError(data.message);
		throw new DefinedError('Unknown error');
	} catch (error) {
		if (error instanceof DefinedError) throw error;
		if (process.env.NODE_ENV === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}
		throw error;
	}
}

import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Text } from '@mantine/core';
import Block from '@block/Block';
import { UseInManagerPackageCtx } from '@comp/useInPackageManager';
import { IBlock } from '@interface/IBlock';
import { generateBiteToken, getPreview } from '@lib/api/contentApi';
import { ContentType } from '@lib/enums/contentType.enum';
import { useAuthTokenContext } from '@lib/useAuthTokenContext';

export const Preview: React.FC = () => {
	const isDebugging = process.env.REACT_APP_DEBUG === 'true';
	const [ blocks, setBlocks ] = useState<IBlock[] | null>(null);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
	const { id, type, locale } = useParams();
	const refToken = useRef<string | null>(null);
	const authTokenContext = useAuthTokenContext();

	useEffect(() => {
		async function fetchBlocks () {
			if (isDebugging) {
				const dummyData = await import('@lib/dummyRenderTree');
				return setBlocks(dummyData.dummyModulePreview.data.blocks);
			}

			if (!id) return;

			try {
				setLoading(true);
				if (!type || !Object.values(ContentType).includes(type as ContentType)) {
					throw new Error(`Invalid preview type: '${type}'. Use 'bite', 'module' or 'manager-package'`);
				}
				const token = localStorage.getItem('token');
				if (token) {
					refToken.current = await generateBiteToken({
						id, 
						type, 
						token, 
						locale,
						authToken: authTokenContext.token,
					});
					const data = await getPreview({
						id, 
						type, 
						token: refToken.current,
						authToken: authTokenContext.token,
					});
					setBlocks(data.data.blocks);
				} else {
					throw new Error('You are not logged in');
				}
			} catch (error) {
				setError((error as Error)?.message || 'Unknown error');
			} finally {
				setLoading(false);
			}
		}
		fetchBlocks();
	}, [ id, type, locale, isDebugging, authTokenContext ]);

	if (error.length) return <Text ml="xs" data-testid="error" color="red.9">{error}</Text>;
	if (loading) return <Loader ml="xs" data-testid="loader"/>;
	if (blocks && blocks.length <= 0) return <Text ml="xs" data-testid="empty">Bite is empty</Text>;
	return (
		<UseInManagerPackageCtx.Provider value={true}>
			{blocks?.map((block: IBlock, index: number) => (<Block data={block} key={index}/>))}
		</UseInManagerPackageCtx.Provider>
	);
};

import { IExternal } from '@interface/IExternal';
import React, { useEffect } from 'react';

const External: React.FC<{ data: IExternal }> = ({ data }) => {
	useEffect(() => {
		window.location.href = data.url;
	}, [ data ]);

	return null;
};

export default External;

import { IBlock } from '@interface/IBlock';
import React from 'react';

const Placeholder: React.FC<{data: IBlock, children?: React.ReactNode}> = ({ data, children }) => {
	return (
		<div style={{ border: '5px solid red' }}>
			<h3>Type: {data.type}</h3>
			<p>{JSON.stringify(Object.keys(data))}</p>
			{children}
		</div>
	);
};

export default Placeholder;

import { Webfont } from '@lib/themes/utils';
import React from 'react';
import WebFont from 'webfontloader';

export const FONT_LOADING_TIMEOUT_MS = 5000;

export const loadFonts = (fontDefinitions: Webfont[]) => {
	const fontsByLocation = fontDefinitions.reduce((acc: Record<string, string[]>, font) => {
		acc[font.location] = acc[font.location] || [];
		acc[font.location].push(font.family);
		return acc;
	}, {});

	Object.keys(fontsByLocation).forEach((location) => {
		if (location === 'google') {
			return WebFont.load({
				google: {
					families: fontsByLocation[location],
				},
				timeout: FONT_LOADING_TIMEOUT_MS,
			});
		} else {
			WebFont.load({
				custom: {
					families: fontsByLocation[location],
					urls: [ location ],
				},
				timeout: FONT_LOADING_TIMEOUT_MS,
			});
		}
	});
};

export const fontLoaderContext = React.createContext(
	{
		loadFonts,
	});

const useFontLoader = () => React.useContext(fontLoaderContext);

export default useFontLoader;

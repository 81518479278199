import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { sendMessage } from './messageHandler';

export interface IAuthToken {
  token: string | null;
  error: string | null;
}

const handleMessage = (
	{ data }: MessageEvent<{ response: string; result?: string; error?: string }>,
	setAuthToken: Dispatch<SetStateAction<IAuthToken>>,
) => {
	if (data?.response === 'getAuthToken') {
		if (data.result) {
			setAuthToken({ token: data.result, error: null });
		} else if (data.error) {
			setAuthToken({ token: null, error: data.error });
		} else {
			const unknownError = 'Unknown error occurred while fetching Auth Token';
			setAuthToken({ token: null, error: unknownError });
		}
	}
};

export const useAuthTokenContext = (): IAuthToken => {
	const [ authToken, setAuthToken ] = useState<IAuthToken>({ token: null, error: null });

	useEffect(() => {
		const handler = (event: MessageEvent<any>) => handleMessage(event, setAuthToken);

		window.addEventListener('message', handler);

		sendMessage({ request: 'getAuthToken' });

		return () => window.removeEventListener('message', handler);
	}, []);

	return authToken;
};

export enum BlockType {
	'EXTERNAL' = 'external',
	'HEADER'='header',
	'HEADING'='heading',
	'GROUP'='group',
	'CARD'='card',
	'IMAGE'='image',
	'LIST' ='list',
	'QUOTE'='quote',
	'PARAGRAPH' = 'paragraph',
	'PILLAR'='pillar',
	'BULLETPOINT' = 'bullet',
	'GALLERY' = 'gallery',
	'FOOTER' = 'footer',
	'BANNER' = 'banner'
}
import useBreakpoints from '@comp/useBreakpoints';
import styled from '@emotion/styled';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IHeader } from '@interface/IHeader';
import type { MantineTheme } from '@mantine/core';
import { Container, Flex, Text, Title, useMantineTheme } from '@mantine/core';
import React from 'react';

const HeaderWrap = styled(Flex)`
  background-color: ${({ theme }: {theme: MantineTheme}) => theme.colors.background[0]};
`;

export const IconWrap = styled(Flex)`
  
	height: ${({ theme }: {theme: MantineTheme}) => theme.spacing.xl}px;
  width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.xl}px;
  min-width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.xl}px;
	
	@media (max-width: ${({ theme }: { theme: MantineTheme }) => theme.breakpoints.sm}px) {
    height: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
    width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
    min-width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
  }
  
	border-radius: 50% 0 50% 50%;
`;

export const TitleWrap = styled(Flex)`
  @media (max-width: ${({ theme }: { theme: MantineTheme }) => theme.breakpoints.sm}px) {
  	flex-direction: column;
	}
`;

const Header: React.FC<{data: IHeader, children?: React.ReactNode }> = ({ data }) => {
	const { isSm } = useBreakpoints();
	const theme = useMantineTheme();
	const textColor = theme.colors?.headerColor[0] ? theme.colors?.headerColor[0] : theme.colors?.primary[0];
	return (
		<HeaderWrap pt={isSm ? 'md' : 'lg'} pb={isSm ? 'md' : 'lg'} align="center" mb="xl">
			<Container size={1700} w={isSm ? '100%' : '85%'} color={textColor}>
				<TitleWrap w="100%" gap="sm" m="0">
					<IconWrap align="center" justify="center" bg="primary.0">
						<FontAwesomeIcon icon={faBook} size='lg' color={textColor}/>
					</IconWrap>
					<Flex direction="column" w="100%" gap="0" justify="center">
						<Title color={textColor} size={isSm ? 'h2' : 'h1'} mb={isSm ? 0 : 'sm'}>{data.title}</Title>
						<Text color={textColor} fw="bold" size="lg">{data.subtitle}</Text>
					</Flex>
				</TitleWrap>
			</Container>
		</HeaderWrap>
	);
};

export default Header;

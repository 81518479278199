import BlockContainer from '@block/BlockContainer';
import styled from '@emotion/styled';
import { IBulletPoint } from '@interface/IBulletPoint';
import { Flex, List, Text, Title } from '@mantine/core';
import React from 'react';
import { processFormattedText } from '@lib/utils';

const ListWrap = styled(List)`
	& li::marker {
		color: ${({ theme }) => theme.colors.accent[0]};
		font-size: ${({ theme }) => `${theme.fontSizes.xl}px`};
	}
`;

const BulletPoint: React.FC<{data: IBulletPoint}> = ({ data }) => {
	const { body, number } = data;

	const html = processFormattedText(body);

	return (
		<BlockContainer size='xl' ml="auto" mr="auto">
			<Flex fz="lg" py="sm" align="center">
				{number ? (
					<>
						<Title color="accent.0" order={4} pr="sm">{number}</Title>
						<Text>{body}</Text>
					</>
				) : (
					<ListWrap>
						<List.Item fz="lg">
							<div dangerouslySetInnerHTML={{ __html: html }} />
						</List.Item>
					</ListWrap>
				)}
			</Flex>
		</BlockContainer>
	);
};

export default BulletPoint;

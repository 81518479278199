import { Card, Flex, Text } from '@mantine/core';
import { IconAlertTriangle, IconAlertOctagon } from '@tabler/icons';
import React from 'react';
import { useRouteError } from 'react-router-dom';

type ErrorResponse = {
	data: any;
	status: number;
	statusText: string;
	message?: string;
};

const ErrorPage: React.FC = () => {
	const error = useRouteError();

	const Response404 = (
		<Flex direction="column" justify="center" align="center">
			<IconAlertTriangle size={64} stroke={1.5} />
			<Text size="xl" color="pink">404: Page not found</Text>
		</Flex>
	);

	const GenericErrorResponse = (
		<Flex direction="column" justify="center" align="center">
			<IconAlertOctagon size={64} stroke={1.5} />
			<Text size="xl" color="pink">Unfortunately an error has occured</Text>
		</Flex>
	);

	return (
		<Flex w="100vw" h="90vh" align="center" justify="center">
			<Card>
				{
					error && (error as ErrorResponse).status === 404 ?
						Response404 :
						GenericErrorResponse
				}
			</Card>
		</Flex>
	);
};

export default ErrorPage;

import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Button, Flex, Group, Stack, Text, TextInput } from '@mantine/core';
import styled from '@emotion/styled';
import { generatorLogin } from '@lib/api/contentApi';

const Wrap = styled(Flex)`
	flex: 1;
	flex-direction: column;
	flex-shrink: 0;
	box-shadow: 0 0 8px rgb(0 0 0 / 12%);
	padding: 2rem;
	align-self: center;
    max-width: 30rem;
`;

const GeneratorLogin: React.FC = () => {
	const [ searchParams ] = useSearchParams();
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ token, setToken ] = useState('');
	const [ error, setError ] = useState('');

	useEffect(() => {
		const tkn = localStorage.getItem('token');
		if (tkn) {
			setToken(tkn);
		}
	}, []);

	useEffect(() => {
		// clear error when user types
		setError('');
	}, [ username, password ]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		try {
			const { data } = await generatorLogin({ username, password });
			localStorage.setItem('token', data.token);
			setToken(data.token);
		} catch (error) {
			setError((error as Error)?.message || 'Unknown error');
		}
	};

	if (token) {
		const returnUrl = searchParams.get('return_url');
		if (returnUrl) {
			return <Navigate to={returnUrl} />;
		}
		return <Navigate to="/" />;
	}

	return (
		<Flex w="100vw" h="100vh" justify="center">
			<Wrap>
				<Text style={{ fontSize: '40px' }}>
                Preview Login
				</Text>
				<form onSubmit={handleSubmit}>
					<Stack>
						<TextInput
							required
							label="Name"
							placeholder="Name"
							value={username}
							onChange={event => setUsername(event.currentTarget.value)}
						/>
						<TextInput
							required
							label="Password"
							placeholder="Password"
							type="password"
							value={password}
							onChange={event => setPassword(event.currentTarget.value)}
						/>
						{error && <Text color="pink">{error}</Text>}
						<Group position="apart">
							<Button type="submit">Login to Generator</Button>
						</Group>
					</Stack>
				</form>
			</Wrap>
		</Flex>
	);
};

export default GeneratorLogin;

import BlockContainer from '@block/BlockContainer';
import Image from '@block/Image';
import { BlockType } from '@lib/enums/blockType.enum';
import { IGallery } from '@interface/IGallery';
import { SimpleGrid, useMantineTheme } from '@mantine/core';
import React from 'react';


const Gallery: React.FC<{data: IGallery, children?: React.ReactNode }> = ({ data }) => {
	const theme = useMantineTheme();
	return (
		<BlockContainer size='xl'>
			<SimpleGrid
				cols={3}
				p="md"
				breakpoints={[
					{ maxWidth: theme.breakpoints.md, cols: 2, spacing: 'sm' },
					{ maxWidth: theme.breakpoints.sm, cols: 1, spacing: 'sm' },
				]}
			>
				{data.images.map(image => (
					<Image data={{
						type: BlockType.IMAGE,
						id: image.id,
						url: image.url,
					}}
					key={image.id}
					/>
				))}
			</SimpleGrid>
		</BlockContainer>
	);
};

export default Gallery;

import styled from '@emotion/styled';
import { IImage } from '@interface/IImage';
import useLightbox from '@lib/useLightbox';
import {
	ActionIcon,
	Container,
	createPolymorphicComponent,
	Flex,
	Image as MantineImage,
	MantineTheme,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { ActionIconProps } from '@mantine/core/lib/ActionIcon/ActionIcon';
import { IconMaximize } from '@tabler/icons';
import React from 'react';
import { processFormattedText } from '@lib/utils';

const _FullscreenIcon = styled(ActionIcon)`
	position: absolute;
	right: ${({ theme }: { theme: MantineTheme }) => theme.spacing.xs}px;
	top: ${({ theme }: { theme: MantineTheme }) => theme.spacing.xs}px;
  height: ${({ theme }: { theme: MantineTheme }) => theme.spacing.md}px;
  min-height: ${({ theme }: { theme: MantineTheme }) => theme.spacing.sm}px;
  width: ${({ theme }: { theme: MantineTheme }) => theme.spacing.md}px;
  min-width: ${({ theme }: { theme: MantineTheme }) => theme.spacing.xs}px;
  padding: 0;
	z-index: 1;
`;
const FullscreenIcon = createPolymorphicComponent<'button', ActionIconProps>(_FullscreenIcon);

const Image: React.FC<{data: IImage, children?: React.ReactNode }> = ({ data }) => {
	const theme = useMantineTheme();
	const lightbox = useLightbox();
	return (
		<Container size='xl' my="lg" sx={{ position: 'relative' }}>
			<FullscreenIcon onClick={() => {
				lightbox?.setImages([ { src: data.url, alt: data.description || data.fileDescription || '' } ]);
				lightbox?.setOpen(true);
			}}>
				<IconMaximize size={theme.spacing.md} />
			</FullscreenIcon>

			<Flex direction="column" align="center">
				<Flex direction="column" align="center" w="85%" m="auto">
					<MantineImage src={data.url} alt={data.description || data.fileDescription} height="auto" bg="transparent" />
					{ data.caption ? (
						<Text mt="sm">
							<div dangerouslySetInnerHTML={{ __html: processFormattedText(data.caption) }} />
						</Text>
					) : null }
				</Flex>
				{ data.description ? (
					<Text>
						<div dangerouslySetInnerHTML={{ __html: processFormattedText(data.description) }} />
					</Text>
				) : null }

			</Flex>
		</Container>
	);
};

export default Image;

import BlockContainer from '@block/BlockContainer';
import useBreakpoints from '@comp/useBreakpoints';
import useInFooter from '@comp/useInFooter';
import styled from '@emotion/styled';
import { IHeading } from '@interface/IHeading';
import { Flex, Image, MantineTheme, Text, Title, useMantineTheme } from '@mantine/core';
import React from 'react';

const IconWrap = styled(Flex)`
	height: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
	max-width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
	min-width: ${({ theme }: {theme: MantineTheme}) => theme.spacing.lg}px;
	& > div {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
`;

const Heading: React.FC<{data: IHeading, children?: React.ReactNode }> = ({ data }) => {
	const { color } = data;
	const { isSm } = useBreakpoints();
	const isInFooter = useInFooter();
	const theme = useMantineTheme();

	const textColor = isInFooter ? theme.colors.footerHeadingColor[0] : (color === 'accent' ? 'accent.0' : theme.colors.headingColor[0]);
	return (
		<BlockContainer mt="xl" size="xl" w={isInFooter ? '100%' : ''}>
			<Title
				id={data.id}
				order={isSm ? 3 : 2}
				color={textColor}
				sx={{ display: 'flex', alignItems: 'baseline' }}
			>
				{data.icon ? (
					<IconWrap mr="sm"><Image src={`${data.icon}?width=200`} alt={data.text} /></IconWrap>
				) : null }
				{data.number && !data.icon ? <Text span color={`${color}.0`} pr="0.4rem">{data.number}.</Text> : null}
				{data.text}
			</Title>
		</BlockContainer>
	);
};

export default Heading;

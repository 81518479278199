import useInFooter from '@comp/useInFooter';
import useInGroup from '@comp/useInGroup';
import styled from '@emotion/styled';
import { Container, ContainerProps, MantineTheme } from '@mantine/core';
import React from 'react';

const FirstLevelBlockContainer = styled(Container)`
	box-sizing: border-box;
	width: 66%;
	@media (max-width: ${({ theme }: { theme: MantineTheme }) => theme.breakpoints.sm}px) {
		width: 100%;
	}
`;

const NestedBlockContainer = styled(Container)`
	margin: 0 auto;
	box-sizing: border-box;
`;

const BlockContainer: React.FC<{children: React.ReactNode} & ContainerProps> = ({ children, ...props }) => {
	const isInGroup = useInGroup();
	const isInFooter = useInFooter();
	const isNested = isInGroup || isInFooter;

	return (
		isNested
			? <NestedBlockContainer {...props}>{children}</NestedBlockContainer>
			: <FirstLevelBlockContainer {...props}>{children}</FirstLevelBlockContainer>
	);
};

export default BlockContainer;

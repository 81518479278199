import BiteView from '@comp/view/BiteView';
import ErrorPage from '@comp/view/ErrorPage';
import GeneratorLogin from '@comp/view/GeneratorLogin';
import HomeView from '@comp/view/HomeView';
import { ManagerPackage } from '@comp/view/ManagerPackage';
import { Preview } from '@comp/view/Preview';
import RequireGenAuth from '@comp/view/RequireGenAuth';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
	{
		path: '/',
		element: <HomeView />,
		errorElement: <ErrorPage/>,
	},
	{
		path: '/bite/:biteName',
		element: <BiteView />,
		errorElement: <ErrorPage/>,
	},
	{
		path: '/generator/login',
		element: <GeneratorLogin />,
		errorElement: <ErrorPage/>,
	},
	// We need a preview for when the locale is not set, since optional params are not supported
	{
		path: '/preview/:type/:id',
		element: <RequireGenAuth element={<Preview />} />,
		errorElement: <ErrorPage/>,
	},
	{
		path: '/preview/:type/:id/:locale',
		element: <RequireGenAuth element={<Preview />} />,
		errorElement: <ErrorPage/>,
	},
	{
		path: '/public/module/:id/manager-package/:locale',
		element: <ManagerPackage/>,
		errorElement: <ErrorPage/>,
	},
]);


export default router;

import { CustomTheme, makeTen } from '@lib/themes/utils';

export default {
	mantine: {
		fontFamily: 'Open Sans Condensed, sans-serif',
		headings: {
			fontFamily: 'Open Sans Condensed, sans-serif',
			sizes: {
				h1: { fontSize: 54, lineHeight: '60px', marginBottom: '16px' },
				h2: { fontSize: 32, lineHeight: 1.35, fontWeight: undefined },
				h3: { fontSize: 28, lineHeight: 1.4, fontWeight: undefined },
				h4: { fontSize: 24, lineHeight: 1.45, fontWeight: undefined },
				h5: { fontSize: 22, lineHeight: 1.5, fontWeight: undefined },
				h6: { fontSize: 20, lineHeight: 1.5, fontWeight: undefined },
			},
		},
		colors: {
			'default': makeTen('#000000'),
			background: makeTen('#4635dd'),
			primary: makeTen('#4e4cec'),
			accent: makeTen('#4e4cec'),
			headerColor: makeTen('#ffffff'),
			headingColor: makeTen('#000000'),
			paragraphColor: makeTen('#4e4cec'),
			paragraphBackground: makeTen('#ffffff'),
			footer: makeTen('#671ee1'),
			footerHeadingColor: makeTen('#ffffff'),
			footerIconBackground: makeTen('#ffffff'),
			footerIconColor: makeTen('#4e4cec'),
			pillarNumberBackground: makeTen('#2948da'),
			pillarNumberColor: makeTen('#ffffff'),
		},
		spacing: {
			xs: 8,
			sm: 16,
			md: 32,
			lg: 48,
			xl: 64,
		},
		components: {
			Container: {
				defaultProps: {
					sizes: {
						xs: 540,
						sm: 720,
						md: 960,
						lg: 1140,
						xl: 1440,
					},
				},
			},
		},
	}
	,
	fonts: [
		{
			family: 'Open Sans Condensed:300,400,700&display=swap',
			location: 'google',
		},
	],
} as CustomTheme;

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type RequireGenAuthProps = {
	element: JSX.Element;
};

const RequireGenAuth: React.FC<RequireGenAuthProps> = (props: RequireGenAuthProps) => {
	const location = useLocation();
	const { element } = props;
	const token = localStorage.getItem('token');

	if (token) {
		return element;
	} else {
		const returnUrl = location.pathname + location.search;
		return <Navigate to={`/generator/login${returnUrl ? `/?return_url=${encodeURIComponent(returnUrl)}` : ''}`} />;
	}
};

export default RequireGenAuth;

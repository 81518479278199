import Dompurify, { addHook } from 'dompurify';
import { marked } from 'marked';

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

addHook('beforeSanitizeAttributes', function (node) {
	if (node.tagName === 'A') {
		if (node.hasAttribute('target')) {
			node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target') as string);
		}
	}
});

addHook('afterSanitizeAttributes', function (node) {
	if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
		node.setAttribute('target', '_blank');
		node.removeAttribute(TEMPORARY_ATTRIBUTE);
		if (node.getAttribute('target') === '_blank') {
			node.setAttribute('rel', 'noopener noreferrer');
		}
	}
});

export function processFormattedText (text: string) {
	return Dompurify.sanitize(marked(text));
}

export const sendMessage = (message: unknown) => {
	const mobileHandler = getMobileHandler();
	const browserHandler = window.top === window.self ? window : window.parent;

	mobileHandler?.postMessage?.(message);

	if (browserHandler) {
		browserHandler.postMessage(message, '*');
	}
};

const getMobileHandler = () => {
	const iOSHandler = window.webkit?.messageHandlers?.htmlMessageMobile;
	const androidHandler = window.Android;

	if (androidHandler) return androidHandler;
	if (iOSHandler) return iOSHandler;

	return null;
};

declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        htmlMessageMobile: {
          postMessage: (message: unknown) => void;
        };
      };
    };
    Android?: {
      postMessage: (message: unknown) => void;
    };
  }
}

import { useEffect, useState } from 'react';
import { sendMessage } from './messageHandler';

export interface IUserContext {
  bite: {
    biteId?: number;
    biteTitle?: string;
    contentStackUid?: string;
    contentStackTypeUid?: string;
    moduleId?: number;
    moduleTitle?: string;
    programCatalogueCode?: number;
    programId?: number;
    programName?: string;
    waveId?: number;
    waveLanguageCode?: string;
    waveName?: string;
  };
  loggedInUser: {
    companyCode?: string;
    companyName?: string;
    email?: string;
    enrolledModules?: {
      id?: number;
      title?: string;
      catalogueCode?: string;
    }[];
    firstName?: string;
    lastName?: string;
    userId?: number;
    userLanguageCode?: string;
  };
}

export const useUserContext = (): IUserContext => {
	const [ userContext, setUserContext ] = useState<IUserContext>({
		bite: {},
		loggedInUser: {},
	});

	useEffect(() => {
		const handler = (event: MessageEvent<any>) =>
			handleMessage(event, setUserContext);

		window.addEventListener('message', handler);

		loadUserContext();

		return () => window.removeEventListener('message', handler);
	}, []);

	return userContext;
};

const handleMessage = (
	{ data }: MessageEvent<{ response: string; result: IUserContext }>,
	setUserContext: React.Dispatch<React.SetStateAction<IUserContext>>,
): void => {
	if (data?.response === 'getUser') {
		setUserContext(data.result as IUserContext);
	}
};

const loadUserContext = (): void => sendMessage({ request: 'getUser' });

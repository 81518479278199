import { BlockType } from '@lib/enums/blockType.enum';
import BulletPoint from '@block/BulletPoint';
import Card from '@block/Card';
import Gallery from '@block/Gallery';
import Group from '@block/Group';
import Header from '@block/Header';
import Heading from '@block/Heading';
import Image from '@block/Image';
import List from '@block/List';
import Paragraph from '@block/Paragraph';
import Pillar from '@block/Pillar';
import Placeholder from '@block/Placeholder';
import Quote from '@block/Quote';
import { IBlock } from '@interface/IBlock';
import React from 'react';
import Footer from '@block/Footer';
import Banner from '@block/Banner';
import External from '@block/External';

type BlockComponent = React.FC<{ data: IBlock, children?: React.ReactNode }>;
const typeToComp = (type: BlockType): BlockComponent => {
	switch (type) {
	case BlockType.BULLETPOINT:
		return BulletPoint as BlockComponent;
	case BlockType.CARD:
		return Card as BlockComponent;
	case BlockType.FOOTER:
		return Footer as BlockComponent;
	case BlockType.GALLERY:
		return Gallery as BlockComponent;
	case BlockType.GROUP:
		return Group as BlockComponent;
	case BlockType.HEADER:
		return Header as BlockComponent;
	case BlockType.HEADING:
		return Heading as BlockComponent;
	case BlockType.IMAGE:
		return Image as BlockComponent;
	case BlockType.LIST:
		return List as BlockComponent;
	case BlockType.PARAGRAPH:
		return Paragraph as BlockComponent;
	case BlockType.PILLAR:
		return Pillar as BlockComponent;
	case BlockType.QUOTE:
		return Quote as BlockComponent;
	case BlockType.BANNER:
		return Banner as BlockComponent;
	case BlockType.EXTERNAL:
		return External as BlockComponent;
	default:
		return Placeholder;
	}
};
const Block: React.FC<{data: IBlock}> = ({ data }) => {
	const Component = typeToComp(data.type);
	if (data.elements) {
		return (
			<Component data={data}>
				{data.elements.map((element: IBlock, index: number) => (<Block data={element} key={index} />))
				}</Component>
		);
	}
	return <Component data={data} />;
};

export default Block;

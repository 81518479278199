import Block from '@block/Block';
import { UseInManagerPackageCtx } from '@comp/useInPackageManager';
import { IBlock } from '@interface/IBlock';
import { getManagerPackage } from '@lib/api/contentApi';
import { Loader, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const ManagerPackage: React.FC = () => {
	const isDebugging = process.env.REACT_APP_DEBUG === 'true';
	const [ blocks, setBlocks ] = useState<IBlock[] | null>(null);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
	const { id, locale } = useParams();

	useEffect(() => {
		async function fetchBlocks () {
			if (isDebugging) {
				const dummyData = await import('@lib/dummyRenderTree');
				return setBlocks(dummyData.dummyManagerPackage.data.blocks);
			}
			if (!id || !locale) return;
			try {
				setLoading(true);
				const data = await getManagerPackage({
					id, 
					locale,
				});
				setBlocks(data.data.blocks);
			} catch (error) {
				setError((error as Error)?.message || 'Unknown error');
			} finally {
				setLoading(false);
			}
		}
		fetchBlocks();
	}, [ id, locale, isDebugging ]);

	if (error.length) return <Text ml="xs" data-testid="error" color="red.9">{error}</Text>;
	if (loading) return <Loader ml="xs" data-testid="loader"/>;
	if (blocks && blocks.length <= 0) return <Text ml="xs" data-testid="empty">Bite is empty</Text>;
	return (
		<UseInManagerPackageCtx.Provider value={true}>
			{blocks?.map((block: IBlock, index: number) => (<Block data={block} key={index}/>))}
		</UseInManagerPackageCtx.Provider>
	);
};

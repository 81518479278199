import { ThemeState, tokens } from '@lepaya/aya-ui-react';
import { CustomTheme, makeTen } from '@lib/themes/utils';

export default function theme (ayaTheme: ThemeState): CustomTheme {
	return {
		mantine: {
			fontFamily: 'Lato, sans-serif',
			headings: {
				fontFamily: 'Lato, sans-serif',
				sizes: {
					h1: { fontSize: 54, lineHeight: '60px', marginBottom: '16px' },
					h2: { fontSize: 32, lineHeight: 1.35, fontWeight: undefined },
					h3: { fontSize: 28, lineHeight: 1.4, fontWeight: undefined },
					h4: { fontSize: 24, lineHeight: 1.45, fontWeight: undefined },
					h5: { fontSize: 22, lineHeight: 1.5, fontWeight: undefined },
					h6: { fontSize: 20, lineHeight: 1.5, fontWeight: undefined },
				},
			},
			colors: {
				'default': makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
				background: makeTen(ayaTheme.value.string(tokens.colorBrandAccentDecorativeSubdued)),
				primary: makeTen(ayaTheme.value.string(tokens.colorBrandAccentDecorative)),
				accent: makeTen(ayaTheme.value.string(tokens.colorBrandPrimary)),
				headerColor: makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
				headingColor: makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
				paragraphBackground: makeTen(ayaTheme.value.string(tokens.colorBgDefault)),
				paragraphColor: makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
				footer: makeTen(ayaTheme.value.string(tokens.colorBgBrandPrimary50)),
				footerHeadingColor: makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
				footerIconBackground: makeTen(ayaTheme.value.string(tokens.colorBgPrimary)),
				footerIconColor: makeTen(ayaTheme.value.string(tokens.colorBgSurface)),
				pillarNumberBackground: makeTen(ayaTheme.value.string(tokens.colorBrandAccentDecorative)),
				pillarNumberColor: makeTen(ayaTheme.value.string(tokens.colorTextDefault)),
			},
			spacing: {
				xs: 8,
				sm: 16,
				md: 32,
				lg: 48,
				xl: 64,
			},
			components: {
				Container: {
					defaultProps: {
						sizes: {
							xs: 540,
							sm: 720,
							md: 960,
							lg: 1140,
							xl: 1440,
						},
					},
				},
			},
		},
		fonts: [
			{
				family: 'Lato:300,400,700&display=swap',
				location: 'google',
			},
		],
	} as CustomTheme;
}

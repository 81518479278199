import BlockContainer from '@block/BlockContainer';
import { QuoteIcon } from '@block/QuoteIcon';
import { IQuote } from '@interface/IQuote';
import { Blockquote } from '@mantine/core';
import React from 'react';


const Quote: React.FC<{data: IQuote, children?: React.ReactNode }> = ({ data }) => {
	const quoteColor = data.color?.toLowerCase() ?? 'default';

	return (
		<BlockContainer size='xl' p="0">
			<Blockquote
				cite={(data.quotee || '').toUpperCase()}
				color={`${quoteColor}.0`}
				icon={<QuoteIcon size={54} />}
			>{data.citation}</Blockquote>
		</BlockContainer>
	);
};

export default Quote;

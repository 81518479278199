import { BlockType } from '@lib/enums/blockType.enum';
import { IBlock } from '@interface/IBlock';

export enum Bulleting {
    Numbered = 'number',
    Bulleted = '>'
}

export interface IList extends IBlock {
    type: BlockType.LIST;
    id: string;
    body: string[];
    bulleting: Bulleting;
}

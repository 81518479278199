import React from 'react';
import { IBanner } from '@interface/IBanner';
import { Flex, Text, Title } from '@mantine/core';
import styled from '@emotion/styled';

const ImageWrap = styled(Flex)`
  img {
	  width: 100px;
  }
`;

const Wrap = styled(Flex)`
	flex: 1;
	flex-direction: column;
	flex-shrink: 0;
	box-shadow: 0 0 8px rgb(0 0 0 / 12%);
	padding: 32px;
	align-self: stretch;
`;

const TextWrap = (
	{ children, ...props } :
		{ children: React.ReactNode, [key: string]: string | number | React.ReactNode },
) => (
	<Text {...props} sx={{ letterSpacing: '0.018em' }} fz={'18px'} fw={400}>{children}</Text>
);

const StyledLi = styled.li`
	padding-bottom: 10px;
	-webkit-font-smoothing: antialiased;
	&::marker {
		color: ${({ theme }) => theme.colors.accent[0]};
		font-size: ${({ theme }) => `${theme.fontSizes.xl}px`};
	}
`;

const Banner: React.FC<{data: IBanner}> = ({ data }) => {
	return (
		<Wrap>
			<Flex direction="column">
				{ data.image ? (
					<ImageWrap justify="center">
						<img alt={data.title} src={data.image} />
					</ImageWrap>
				) : null}
				<Title mb="sm" order={5} align="center">{data.title}</Title>
				{
					data.bullet ? (
						<Flex>
							<ul>
								{data.text.map((t, i) => (
									<StyledLi key={i}>
										<TextWrap>{t}</TextWrap>
									</StyledLi>
								))}
							</ul>
						</Flex>
					) : data.text.map((t, i) => (
						<TextWrap key={i} mb="sm">{t}</TextWrap>
					))
				}
			</Flex>
		</Wrap>
	);
};

export default Banner;
import { ThemeState } from '@lepaya/aya-ui-react';
import lepayaTheme from '@lib/themes/lepayaTheme';
import sublime from '@lib/themes/sublime';
import kpmg from '@lib/themes/kpmg';
import { CustomTheme } from '@lib/themes/utils';

export function getTheme (companyCode: string, ayaTheme: ThemeState) {
	const map: Record<string, CustomTheme> = {
		'codeus': sublime,
		'kpmg': kpmg,
	};
	return Promise.resolve(map[companyCode ? companyCode.toLowerCase() : ''] ?? lepayaTheme(ayaTheme));
}

import { useAyaTheme } from '@lepaya/aya-ui-react';
import { getTheme } from '@lib/api/themeApi';
import useFontLoader from '@lib/themes/fontload';
import { CustomTheme } from '@lib/themes/utils';
import { LightboxContext } from '@lib/useLightbox';
import { useUserContext } from '@lib/useUserContext';
import { Loader, MantineProvider } from '@mantine/core';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import router from './router';

const Shell = () => {
	const ayaTheme = useAyaTheme();
	const fonts = useFontLoader();
	const userContext = useUserContext();
	const [ theme, setTheme ] = useState<CustomTheme | undefined>(undefined);
	const [ isLightboxOpen, setIsLightboxOpen ] = useState<boolean>(false);
	const [ lightboxImages, setLightboxImages ] = useState<ImagesListType>([]);

	useEffect(() => {
		const loadTheme = async () => {
			const scheme = await getTheme(userContext?.loggedInUser.companyCode ?? '', ayaTheme);
			if (scheme?.fonts) { fonts.loadFonts(scheme.fonts); }
			setTheme(scheme);
		};
		loadTheme();
	}, [ fonts, ayaTheme , userContext ]);

	if (!theme) {
		return null;
	}

	return (
		<MantineProvider theme={theme.mantine} withNormalizeCSS withGlobalStyles>
			<Lightbox
				isOpen={isLightboxOpen}
				onClose={() => setIsLightboxOpen(false)}
				images={lightboxImages}
				currentIndex={0}
				onPrev={() => {}}
				onNext={() => {}}
				style={{ background: 'rgba(0,0,0,0.85)' }}
			/>
			<LightboxContext.Provider value={{
				isOpen: isLightboxOpen,
				images: lightboxImages,
				setImages: setLightboxImages,
				setOpen: setIsLightboxOpen,
			}}>
				<RouterProvider router={router} />
			</LightboxContext.Provider>
		</MantineProvider>
	);
};

export default Shell;

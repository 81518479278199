import { IconWrap } from '@block/Header';
import useBreakpoints from '@comp/useBreakpoints';
import { UseInFooterCtx } from '@comp/useInFooter';
import styled from '@emotion/styled';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Flex, useMantineTheme } from '@mantine/core';
import React from 'react';

const FooterWrap = styled(Flex)`

`;


const Footer: React.FC<{children: React.ReactNode}> = ({ children }) => {
	const theme = useMantineTheme();
	const { isSm } = useBreakpoints();
	return (
		<Container size='xl'>
			<FooterWrap bg="footer.0" gap="sm" direction={{ base: 'column', sm: 'row' }} p={isSm ? 'sm' : 'lg'} pl={isSm ? 'md' : 'xl'}>
				<Flex pl={isSm ? 'md' : ''}>
					<IconWrap align="center" justify="center" bg="footerIconBackground.0" >
						<FontAwesomeIcon icon={faLightbulb} size="lg" color={theme.colors.footerIconColor[0]}/>
					</IconWrap>
				</Flex>
				<Flex direction="column" sx={{ flex: 1 }} p="0" gap="sm">
					<UseInFooterCtx.Provider value={true}>
						{children}
					</UseInFooterCtx.Provider>
				</Flex>
			</FooterWrap>
		</Container>
	);
};

export default Footer;

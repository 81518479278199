import React from 'react';
import { ImagesListType } from 'react-spring-lightbox';

export const LightboxContext = React.createContext<{
	isOpen: boolean;
	setOpen:(isOpen: boolean) => void;
	images: ImagesListType;
	setImages: (images: ImagesListType) => void;
		}|null>(null);

export const useLightbox = () => React.useContext(LightboxContext);

export default useLightbox;

import BlockContainer from '@block/BlockContainer';
import useBreakpoints from '@comp/useBreakpoints';
import useInFooter from '@comp/useInFooter';
import useInGroup from '@comp/useInGroup';
import { ICard } from '@interface/Icard';
import { Card as MantineCard, Group, Image, Text, Title } from '@mantine/core';


const Card: React.FC<{data: ICard, children?: React.ReactNode }> = ({ data }) => {
	const { isSm } = useBreakpoints();
	const isInGroup = useInGroup();
	const isInFooter = useInFooter();
	const isNested = isInGroup || isInFooter;

	return (
		<BlockContainer size="xl" p="0" >
			<MantineCard
				withBorder
				radius="md"
				p="md"
				id={data.id}
				w={ (isSm || isNested) ? '100%' : '33.3%' }
				sx={{ maxWidth: '450px' }}
			>
				{data.image ? (
					<MantineCard.Section>
						<Image src={data.image} alt={data.title} height={330} sx={{ objectFit: 'cover' }}/>
					</MantineCard.Section>
				) : null}

				<Group position="apart" mt="md" mb="xs">
					<Title order={ isSm ? 5 : 4}>
						{data.title}
					</Title>
				</Group>

				{data.text ? (
					<Text size={isSm ? 'md' : 'lg'} color='dimmed'>
						{data.text}
					</Text>
				) : null}
			</MantineCard>
		</BlockContainer>
	);
};

export default Card;

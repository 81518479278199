import { ErrorBoundary } from '@sentry/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Shell from './Shell';
import { LearnerThemeProvider } from '@lepaya/aya-ui-react';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		defaultIntegrations: false,
	});
}

window.addEventListener('error', (e: ErrorEvent) => {
	//Prevents double throwing of errors, a React problem
	if (e.error.hasBeenCaught !== undefined) {
		return false;
	}
	e.error.hasBeenCaught = true;
	Sentry.captureException(e);
});

window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) => Sentry.captureException(e));

root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<LearnerThemeProvider>
				<Shell />
			</LearnerThemeProvider>
		</ErrorBoundary>
	</React.StrictMode>,
);

import BlockContainer from '@block/BlockContainer';
import { UseInGroupCtx } from '@comp/useInGroup';
import { IGroup } from '@interface/IGroup';
import { Container, Group as MantineGroup } from '@mantine/core';
import React from 'react';
import useInManagerPackage from '@comp/useInPackageManager';


const Group: React.FC<{data: IGroup, children?: React.ReactNode}> = ({ data, children }) => {
	const isInManagerPackage = useInManagerPackage();

	const Comp = isInManagerPackage ? Container : BlockContainer;

	const style = isInManagerPackage ? { padding: '32px' } : { padding: 0 };

	return (
		<Comp size="xl" sx={style}>
			<MantineGroup
				id={data.id}
				position="center"
				align="center"
				w={'100%'}
				p={'sm'}
			>
				<UseInGroupCtx.Provider value={true}>
					{children}
				</UseInGroupCtx.Provider>
			</MantineGroup>
		</Comp>
	);
};

export default Group;

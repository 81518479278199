function parseToken (token: string | null): {
  locale?: string;
  contentId?: string;
  contentType?: string;
} {
	if (!token) return {};

	try {
		const payload = JSON.parse(atob(token.split('.')[1]));

		return {
			locale: payload.locale,
			contentId: payload.id,
			contentType: payload.type,
		};
	} catch (error) {
		return {};
	}
}

export function mergeBiteParams (
	biteContext: {
    waveLanguageCode?: string;
    contentStackUid?: string;
    contentStackTypeUid?: string;
  },
	queryString: {
    locale: string | null;
    contentId: string | null;
    contentType: string | null;
  },
	token: string | null,
): {
  locale: string | null;
  contentId: string | null;
  contentType: string | null;
} {
	const payload = parseToken(token);

	return {
		locale:
      biteContext.waveLanguageCode ?? payload.locale ?? queryString.locale,
		contentId:
      biteContext.contentStackUid ?? payload.contentId ?? queryString.contentId,
		contentType:
      biteContext.contentStackTypeUid ??
      payload.contentType ??
      queryString.contentType,
	};
}
